<template>
  <div>
    <!-- <div>lineId = {{lineId}}</div>
    <div>$liff_ID_Point = {{$liff_ID_Point}}</div> -->
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Point })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    this.lineId = this.$route.query.LineId;
    if (!this.lineId) {
      this.lineId = decodeURIComponent(window.location.search).split(
        "liff.state=/"
      )[1];
    }
    if (!this.lineId) this.lineId = this.$route.params.LineId;
    this.getToken();
  },

  data() {
    return {
      lineId: ""
      // getOs: ""
    };
  },
  methods: {
    async getToken() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      // this.getOs = this.$liff.getOS();

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginfamilyclub/point/${this.lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (result.detail.url) {
              window.open(result.detail.url, "_self");
              // if (this.getOs == "android") {
              //   setTimeout(() => {
              //     this.closeWindow();
              //   }, 500);
              // }
            } else {
              window.open(`${this.$PATH_SKL}`, "_self");
            }
          } else {
            window.open(`${this.$PATH_SKL}`, "_self");
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style></style>
